import revive_payload_client_Y2aqGtPEjN from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.12.0_jiti@1.21.6__ioredis@5.4.1_7ehiyux5yraitkink7om36uxna/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_eneeTtoJnj from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.12.0_jiti@1.21.6__ioredis@5.4.1_7ehiyux5yraitkink7om36uxna/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_9Hfp08zDZA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.12.0_jiti@1.21.6__ioredis@5.4.1_7ehiyux5yraitkink7om36uxna/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_nmgbvKEwpC from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.16_magicast@0.3.5_rollup@4.21.0_vite@5.4.7_@types+node@22.0.0_sass@1.79._pz6nvndhqbneyik2j5gt7whmxa/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_hDKXV3QwCY from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.12.0_jiti@1.21.6__ioredis@5.4.1_7ehiyux5yraitkink7om36uxna/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_QD4lQbfnlP from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.12.0_jiti@1.21.6__ioredis@5.4.1_7ehiyux5yraitkink7om36uxna/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_CszsG6yd8c from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.12.0_jiti@1.21.6__ioredis@5.4.1_7ehiyux5yraitkink7om36uxna/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_U5FuXs4xKx from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.12.0_jiti@1.21.6__ioredis@5.4.1_7ehiyux5yraitkink7om36uxna/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_gW6rAoyYvB from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.12.0_jiti@1.21.6__ioredis@5.4.1_7ehiyux5yraitkink7om36uxna/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_YnRI5vhQiT from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.21.0_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import sentry_client_I9Z1PSab55 from "/opt/buildhome/repo/node_modules/.pnpm/@sentry+nuxt@8.34.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._5r5uk7s6pwxfu4edfsu5tlrcwe/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import plugin_client_rnOcb3dS1q from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+plausible@1.0.3_magicast@0.3.5_rollup@4.21.0_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import slideovers_WWjdH0RT2m from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_magicast@0.3.5_rollup@4.21.0_vite@5.4.7_@types+node@22.0.0_sass@1.79.5_terser_scv76rgtyboev7za4mb5jfj6ge/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_cFviHswo6D from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_magicast@0.3.5_rollup@4.21.0_vite@5.4.7_@types+node@22.0.0_sass@1.79.5_terser_scv76rgtyboev7za4mb5jfj6ge/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ev21TEkw4S from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_magicast@0.3.5_rollup@4.21.0_vite@5.4.7_@types+node@22.0.0_sass@1.79.5_terser_scv76rgtyboev7za4mb5jfj6ge/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_8tnzP1nF8s from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.21.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_GaYErbKmY5 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.5.6_magicast@0.3.5_rollup@4.21.0_vite@5.4.7_@types+node@22.0.0_sass@1.79.5_terse_sezqcrewsjoygxjhuphwrpqudm/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import documentDriven_DMaP24yvD8 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+content@2.13.2_ioredis@5.4.1_magicast@0.3.5_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+no_2l66ltzrjb5n2eu5uqsd7m7vxa/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import plugin_auto_pageviews_client_br0mic4zFj from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+plausible@1.0.3_magicast@0.3.5_rollup@4.21.0_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_Y2aqGtPEjN,
  unhead_eneeTtoJnj,
  router_9Hfp08zDZA,
  _0_siteConfig_nmgbvKEwpC,
  payload_client_hDKXV3QwCY,
  navigation_repaint_client_QD4lQbfnlP,
  check_outdated_build_client_CszsG6yd8c,
  chunk_reload_client_U5FuXs4xKx,
  components_plugin_KR1HBZs4kY,
  prefetch_client_gW6rAoyYvB,
  plugin_client_YnRI5vhQiT,
  sentry_client_I9Z1PSab55,
  sentry_client_config_o34nk2sJbg,
  plugin_client_rnOcb3dS1q,
  slideovers_WWjdH0RT2m,
  modals_cFviHswo6D,
  colors_ev21TEkw4S,
  plugin_client_8tnzP1nF8s,
  plugin_GaYErbKmY5,
  documentDriven_DMaP24yvD8,
  plugin_auto_pageviews_client_br0mic4zFj
]